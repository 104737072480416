import request from '@/plugins/axios'

export function changePoints(id, value, remark) {
  // 增减积分
  return request({
    url: `authenticate/users/${id}/points`,
    method: 'post',
    data: {
      points_value: value,
      remark
    }
  })
}

export function getPointsMenu(params) {
  // 获取积分详情
  return request({
    url: '/detect/points_historys',
    method: 'get',
    params
  })
}
